.wrapper {
    display: flex;
    align-items: center;

    &:has(.icon) .check-pill {
        --bg-color: transparent;
        --bg-color-hover: transparent;
        --bg-color-has-value: transparent;
        --bg-color-has-value-hover: transparent;
    }

    border-radius: 9999px;
    background: #f5f5f5;

    &:hover {
        background: #eeeeee;
    }
}

.icon {
    display: grid;
    place-content: center;
    border-radius: 50%;
    padding: 9px 10px;
    background: var(--gray-300, #eaeaea);
    color: var(--gray-700, #747474);
    transition: var(--base-transition);
    width: 18px;
    height: 18px;
    box-sizing: content-box;
    cursor: pointer;

    &:hover {
        background: var(--gray-400, #d3d3d3);
    }
}

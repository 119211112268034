@import '../../style/typography.scss';

.page-layout {
    --z-index: 999999999;
    background-color: #f7f7fa;
    margin-block: var(--header-height) 80px;
    padding: 4px 16px 80px 16px;
    width: 100%;
}

.page-title {
    @extend .font-size-200-semibold;
}

.page-subtitle {
    --text-color: var(--gray-600);
    @extend .font-size-75-regular;
}

.page-header {
    display: grid;
    grid-template-areas:
        'title integracao'
        'subtitle _';
    justify-content: start;
    align-items: center;
    column-gap: 0.5rem;
    margin-block-end: 0.25rem;

    h1 {
        grid-area: 'title';
    }

    button {
        grid-area: 'integracao';
    }

    h4 {
        grid-area: 'subtitle';
    }
}

.page-body {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 0.5rem;
    flex: 1 0 0;
    align-self: stretch;
}

.page-section {
    background-color: var(--white);

    &,
    & > * {
        border-radius: 6px;
    }

    &-padded {
        padding: 0.75rem;
    }
}

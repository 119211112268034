.placeholder-wrapper {
    display: flex;
    align-items: center;
    gap: 4px;

    &__count {
        background: #3498ff;
        color: #fff;
        border-radius: 10px;
        padding: 0 10px;
        line-height: 20px;
    }
}

.check-picker {
    --bg-color: #f5f5f5;
    --bg-color-hover: #eeeeee;
    --bg-color-has-value: #f5f9fe;
    --bg-color-has-value-hover: #f5f9fe;
    --placeholder-color: #505050;
    --placeholder-color-has-value: #0d66d0;
    --placeholder-color-has-value-hover: #0d66d0;

    :global(.rs-picker-toggle) {
        transition: var(--base-transition);
        background: var(--bg-color, #f5f5f5) !important;
        border-radius: 9999px !important;
        &:hover {
            background: var(--bg-color-hover, #eeeeee) !important;
        }
    }

    :global(.rs-picker-toggle-placeholder),
    .placeholder-wrapper__text {
        color: var(--placeholder-color, #505050);
    }

    &:global(.rs-picker-has-value) :global(.rs-picker-toggle) {
        background: var(--bg-color-has-value, #f5f9fe) !important;

        :global(.rs-picker-toggle-placeholder),
        :global(.rs-picker-toggle-clean),
        :global(.rs-picker-toggle-caret),
        .placeholder-wrapper__text {
            color: var(--placeholder-color-has-value, #0d66d0);
        }

        :global(.rs-picker-toggle-clean) {
            background: none !important;
            transform: translateX(160%);
        }

        :global(.rs-picker-toggle-caret) {
            transform: translateX(-160%);
        }

        &:hover {
            background: var(--bg-color-has-value-hover, #f5f9fe) !important;

            :global(.rs-picker-toggle-placeholder),
            :global(.rs-picker-toggle-clean),
            :global(.rs-picker-toggle-caret),
            .placeholder-wrapper__text {
                color: var(--placeholder-color-has-value-hover, #0d66d0);
            }
        }
    }
}
